<template>
    <div class="querytoolbarSR">
        <el-form class="ToolBarFromQR" :inline="true" :model="classParam">
            <el-form-item label="院校名称">
                <el-input v-model="classParam.name" :formatter="formatter" clearable placeholder="请输入院校名称"
                    style="width: 160px;" />
            </el-form-item>
            <el-form-item label="专业名称">
                <el-input v-model="classParam.majorname" :formatter="formatter" clearable placeholder="请输入专业名称"
                    style="width: 160px;" />
            </el-form-item>
            <el-form-item label="专业选考科目要求">
                <el-select v-model="classParam.requirement" clearable placeholder="请选择专业选考科目要求" style="width: 200px;">
                    <el-option v-for="item in requirements" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import userRequest from '@/network/userRequest';
export default {
    emits: ['queryPaged'],
    data() {
        return {
            classParam: {
                name: '',
                majorname: '',
                requirement: '',
            },
            requirements: [],
        }
    },
    methods: {
        formatter(value) {
            // 使用正则表达式检查密码是否包含字母、数字、下划线、中文字符和逗号
            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5,]+$/; // 允许字母、数字、下划线、中文字符和逗号
            if (!regex.test(value)) {
                return value.replace(/[^a-zA-Z0-9_\u4e00-\u9fa5,]/g, ''); // 移除非字母、数字、下划线、中文字符和逗号的字符
            } else {
                return value;
            }
        },
        onSubmit() {
            this.$emit('queryPaged');
        },
    },
    mounted() {
        userRequest.listRequirement().then(response => {
            if (response.status == 200) {
                this.requirements = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })

    },


}

</script>

<style>
.querytoolbarSR {
    box-sizing: border-box;
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
}

.ToolBarFromQR {
    display: flex;
    margin-top: 10px;
    justify-content: left;
}

.el-form-item__label {
    padding: 0px 5px 0px 0px;
}
</style>