<template>
  <el-dialog title="更改信息" v-model="isVisible" width="600" class="uodataDialog" draggable>
    <el-form ref="userUpdataFormRef" :model="classData" :rules="myRules">
      <el-form-item label="用户名:" :label-width="fromLableWidth" prop="name">
        <el-input v-model="classData.name" placeholder="请输入用户名" clearable autocomplete="off" required
          style="width:300px ;" />
      </el-form-item>
      <el-form-item label="密码:" :label-width="fromLableWidth" prop="password">
        <el-input v-model="classData.password" clearable show-password placeholder="请输入密码" @change="ispasswordChange"
          autocomplete="off" required style="width:300px ;" />
      </el-form-item>
      <el-form-item v-show="isShow3" label="确认密码:" :label-width="fromLableWidth" prop="ispassword">
        <el-input v-model="classData.ispassword" @blur="pdblur" clearable show-password placeholder="请再次输入密码"
          autocomplete="off" required style="width: 300px;" />
      </el-form-item>
      <el-form-item label="身份:" :label-width="fromLableWidth" prop="identity">
        <el-select v-model="classData.identity" clearable @change="identityChange" placeholder="请选择身份类型"
          style="width:300px ;">
          <el-option v-for="item in identities" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="省份:" :label-width="fromLableWidth" prop="province">
        <el-select v-model="classData.province" clearable placeholder="请选择所在省份" style="width:300px ;">
          <el-option v-for="item in provinces" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="所选大学:" :label-width="fromLableWidth" :prop="prop1" v-show="isShow1">
        <el-autocomplete v-model="classData.selectedsub" :fetch-suggestions="querySearch" clearable
          highlight-first-item="true" style="width: 300px;" :trigger-on-focus="true" class="inline-input w-50"
          placeholder="请输入所选大学" @select="handleSelect" @change="handChange" />
      </el-form-item>

      <el-form-item label="所选科目:" :label-width="fromLableWidth" v-show="isShow2">
        <el-select v-model="oneSelect" clearable placeholder="请选择首先科目" style="width:150px ;">
          <el-option v-for="item in one" :label="item" :value="item" />
        </el-select>
        <el-select v-model="towSelect" multiple :disabled="isDisabled" placeholder="请选择再选科目" @change="twoChange" clearable
          multiple-limit="2" style="width:150px ;">
          <el-option v-for="item in two" :label="item" :value="item" />
        </el-select>
      </el-form-item>

      <el-row justify="center">
        <el-form-item>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 50px;">
          <el-button type="primary" @click="submitData">
            确定
          </el-button>
        </el-form-item>
      </el-row>

    </el-form>
  </el-dialog>
</template>

<script>
import userRequest from '@/network/userRequest';
import { ElMessage } from 'element-plus';
import store from '../store';
import { UTIL } from '../util/index';
export default {
  //组件注入
  components: {
  },
  emits: ['identityChange'],
  //变量初始化
  data() {
    return {
      fromLableWidth: 200,
      isVisible: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isDisabled: false,
      prop1: '',
      prop2: '',
      select: [],
      oneSelect: '',
      towSelect: [],
      classData: {
        id: '',
        name: '',
        password: '',
        identity: '',
        province: '',
        selectedsub: '',
        ispassword: '',
      },
      provinces: [],
      identities: ['高中生', '初中生'],
      one: ['物理', '历史'],
      two: ['思想政治', '地理', '化学', '生物'],
      datas: [],
      myRules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 2, max: 30, message: '请输入2~30个字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格、特殊符号或中文字符
              const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
              if (!regex.test(value)) {
                callback(new Error('用户名只能包含字母、数字、下划线和中文字符'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格或特殊符号
              const regex = /^[a-zA-Z0-9_]+$/; // 此正则表达式允许字母、数字和下划线
              if (!regex.test(value)) {
                callback(new Error('密码只能包含字母、数字和下划线'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        ispassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格或特殊符号
              const regex = /^[a-zA-Z0-9_]+$/; // 此正则表达式允许字母、数字和下划线
              if (!regex.test(value)) {
                callback(new Error('密码只能包含字母、数字和下划线'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },

        ],
        identity: [
          { required: true, message: '请选择身份', trigger: 'blur' },
          // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
        ],
        province: [
          { required: true, message: '请输入学省份', trigger: 'blur' },
          // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
        ],
        selectedsub: [
          { required: true, message: '请输入所选大学', trigger: 'blur' },
          { min: 3, max: 50, message: '请输入4~50个字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 使用正则表达式检查是否包含空格、特殊符号或中文字符
              const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
              if (!regex.test(value)) {
                callback(new Error('只能包含中文字符和字母、数字、下划线'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],

      },
      restaurants: [],
    }
  },
  //方法定义
  methods: {
    ispasswordChange() {
      this.isShow3 = true;
    },
    pdblur() {
      if (this.classData.ispassword != this.classData.password) {
        ElMessage.error('密码不匹配，请再次输入');
        this.classData.ispassword = '';
      }
    },
    twoChange(value) {
    },
    handleSelect(item) {
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    //输入框获取焦点时调用的方法
    querySearch(queryString, cb) {
      //allInfos是怎么来，是从父组件传过来的还是在自己组件调用api接口拿到的还是其他
      //我这里的allInfos是从父组件传过来的，在这里也举例子组件从父组件传值
      let results = this.restaurants;
      results = queryString
        ? results.filter(this.createFilter(queryString))
        : results;
      //cb是回调函数，返回筛选出的结果数据到输入框下面的输入列表
      cb(results);
    },

    resetAllData() {
      for (let pro in this.classData) {
        this.classData[pro] = '';
      }
    },
    identityChange() {
      this.classData.selectedsub = '';
      if (this.classData.identity == "初中生") {
        this.isShow1 = true;
        this.isShow2 = false;
        this.prop1 = "selectedsub";
      }
      else if (this.classData.identity == "高中生") {
        this.prop1 = "";
        this.isShow2 = true;
        this.isShow1 = false;
      }
    },
    cancel() {
      this.isVisible = false;
      this.isShow3 = false;
    },
    submitData() {
      if (this.classData.identity == "高中生") {
        if (this.oneSelect == '') {
          ElMessage.info("请选择首选科目");
        }
        else if (this.towSelect.length != 2) {
          ElMessage.info("请选择两门再选科目");
        }
        else {
          this.classData.selectedsub = "" + this.oneSelect + "," + this.towSelect[0] + "," + this.towSelect[1];
          this.submit();
        }

      } else if (this.classData.identity == "初中生") {
        this.submit();
      }
    },
    submit() {
      this.$refs.userUpdataFormRef.validate((valid, fields) => {
        if (valid) {
          userRequest.updataData(this.classData).then(response => {
            if (response.data.resultCode == 200) {
              store.state.userInfo.id = this.classData.id;
              store.state.userInfo.name = this.classData.name;
              store.state.userInfo.password = this.classData.password;
              store.state.userInfo.identity = this.classData.identity;
              store.state.userInfo.province = this.classData.province;
              store.state.userInfo.selectedsub = this.classData.selectedsub;
              store.state.isLoggedIn = true;
              this.$emit('identityChange');
              let data = JSON.stringify({
                id: this.classData.id,
                name: this.classData.name,
                password: this.classData.password,
                identity: this.classData.identity,
                province: this.classData.province,
                selectedsub: this.classData.selectedsub,
                token: JSON.parse(UTIL.getUserToken()).token,
                isLoggedIn: true,
              });
              // 构造函数
              UTIL.setUserToken(data);
              ElMessage({
                message: '修改成功！',
                type: 'success',
              })
              this.isVisible = false;
              this.resetAllData();

            } else {
              ElMessage.error(response.data.message);
            }
          }).catch(error => {
            ElMessage.info("该用户名已存在！");
          })
        } else {
          this.classData.selectedsub = '';
          console.log('erro submit!', fields);
        }
      })
    }
  },
  // 在组件内容被渲染到页面之后自动执行的函数
  mounted() {
    userRequest.listProvince().then(response => {
      if (response.status == 200) {
        this.provinces = response.data;
      } else {
        ElMessage.error(response.data.message);
      }
    }).catch(error => {
      ElMessage.error(error);
    })
    userRequest.listUniversity().then(response => {
      if (response.status == 200) {
        this.restaurants = response.data;
      } else {
        ElMessage.error(response.data.message);
      }
    }).catch(error => {
      ElMessage.error(error);
    })

  },
  // 当data中的数据发生变化后执行
  updated() {
  },
}

</script>

<style>
.uodataDialog {
  font-family: Verdana, sans-serif;
  font-weight: bold;
}
</style>