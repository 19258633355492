<template>
    <div class="parent-out-sr">
        <div class="srClass">
            <el-page-header @back="onBack" title="返回">
                <template #content>
                    <span class="text-large font-600 mr-3"> 高等院校专业选考科目要求表 </span>
                </template>
            </el-page-header>
            <el-row>
                <QueryToolBar ref="QueryToolBarRef" @queryPaged="queryPaged"></QueryToolBar>
            </el-row>
            <el-row>
                <SRTable ref="SRTableRef" @queryPaged="queryPaged"></SRTable>
            </el-row>
        </div>
    </div>
</template>

<script>
import userRequest from '@/network/userRequest';
import QueryToolBar from './QueryToolBar.vue'
import SRTable from './SRTable.vue'
export default {
    //组件注入
    components: {
        QueryToolBar,
        SRTable,
    },
    //变量初始化
    data() {
        return {
        }
    },
    //方法定义
    methods: {
        onBack() {
            this.$router.push("/home");
        },
        queryPaged(newCurrentPage) {
            this.$refs.SRTableRef.loading = true;
            let param = this.$refs.QueryToolBarRef.classParam;
            let pagedParam = this.$refs.SRTableRef.PageParam;
            if (newCurrentPage && newCurrentPage > 1) {
                param.page = pagedParam.currentPage;
            } else {
                param.page = 1;
                this.$refs.SRTableRef.PageParam.currentPage = 1;
            }
            param.rows = pagedParam.pageSize;
            userRequest.queryPageSR(param).then(response => {
                if (response.data && response.data.rows) {
                    let total = response.data.total;
                    let rows = response.data.rows;
                    this.$refs.SRTableRef.tableData = rows;
                    this.$refs.SRTableRef.PageParam.total = total;
                    this.$refs.SRTableRef.loading = false;
                }
            }).catch(error => {
                console.log(error);
            })
        },
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
};


</script>

<style>
.parent-out-sr{
    width: 100%;
    height: 100%;
}
.srClass {
    font-family: Verdana, sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-weight: bold;
}
</style>