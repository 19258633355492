<template>
  <div id="app" ref="resizeTarget" style="width: 100%; height: 100%;" class="appclass">
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
  </div>
</template>

<script>
// 导入 debounce 函数
const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;
    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
export default {
  name: 'App',
  components: {
  },
  mounted() {
    // 获取 ResizeObserver 类
    const _ResizeObserver = window.ResizeObserver;
    // 创建 ResizeObserver 实例，并在回调函数中添加 debounce
    const resizeObserver = new _ResizeObserver(
      debounce((entries) => {
      }, 16)
    );

    // 获取模板中的元素
    const targetElement = this.$refs.resizeTarget;

    // 开始观察目标元素
    resizeObserver.observe(targetElement);
  },
  beforeMount() {
    //清空浏览器缓存
    // window.localStorage.clear();
    localStorage.removeItem('pageData')
    localStorage.removeItem('listData')
  }
}
</script>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  /* margin-bottom: 1px; */
  box-sizing: border-box;
  /* overflow: auto; */
}

::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: all 2s;
}

::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}
</style>
