<template>
    <div v-show="isShow" class="querytoolbargao">
        <el-form class="ToolBarFrom" :model="classParam">
            <el-form-item label="院校名称" style="margin-bottom: 0px;">
                <el-input size="small" v-model="classParam.universityName" :formatter="formatter" clearable placeholder="请输入院校名称"
                    style="width: 160px;" />
            </el-form-item>
            <el-form-item label="办学类型" style="margin-bottom: 0px;">
                <el-select v-model="classParam.campustype" size="small" clearable placeholder="请选择办学类型"
                    style="width: 200px;">
                    <el-option v-for="item in campustypes" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="院校类型" style="margin-bottom: 0px;">
                <el-select v-model="classParam.universitytype" size="small" clearable placeholder="请选择院校类型"
                    style="width: 200px;">
                    <el-option v-for="item in universitytypes" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>

            <el-form-item label="专业类别" style="margin-bottom: 0px;">
                <el-select v-model="classParam.majorcategory" size="small" clearable placeholder="请选择专业类别"
                style="width: 200px;">
                    <el-option v-for="item in majorcategories" :key="item" :label="item" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item  style="margin-bottom: 0px;">
                <!-- <el-button size="small" type="primary" @click="onSubmit">查询</el-button> -->
                <el-button type="primary" size="small" class="col-button" @click="onSubmit"
                    style="margin-bottom: 5px;">
                    <el-icon style="margin-bottom: 2px;margin-right: 2px;">
                        <Search />
                    </el-icon>查询
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import userRequest from '@/network/userRequest';
export default {
    emits: ['queryPaged'],
    data() {
        return {
            isShow: false,
            classParam: {
                universityName: '',
                campustype: '',
                universitytype: '',
                majorcategory: '',
            },
            majorcategories: [],
            campustypes: [],
            universitytypes: [],
        }
    },
    methods: {
        formatter(value) {
            // 使用正则表达式检查密码是否包含字母、数字、下划线、中文字符和逗号
            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5,]+$/; // 允许字母、数字、下划线、中文字符和逗号
            if (!regex.test(value)) {
                return value.replace(/[^a-zA-Z0-9_\u4e00-\u9fa5,]/g, ''); // 移除非字母、数字、下划线、中文字符和逗号的字符
            } else {
                return value;
            }
        },
        onSubmit() {
            this.$emit('queryPaged');
        }
    },
    mounted() {
        userRequest.queryUnionList().then(response => {
            if (response.status == 200) {
                this.universitytypes = response.data.universitytypes;
                this.campustypes = response.data.campustypes;
                this.majorcategories = response.data.majorcategories;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            console.log(error);
        })
    },


}

</script>

<style lang="scss" src="../assets/qureyToolbBarGao.scss" scoped>
</style>