<template>
    <!-- <div class="el-carousel-out"> -->
    <el-carousel class="el-carousel" :style="{ 'height': calculatedHeight }">
        <el-carousel-item v-for="item in imgwrap" :key="item.url">
            <img class="heard-image" :src="item.url" />
        </el-carousel-item>
    </el-carousel>
    <!-- </div> -->
</template>
<script>
export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            imgwrap: [
                { url: require("./img/zoumadeng/1.jpg") },
                { url: require("./img/zoumadeng/2.jpg") },
                { url: require("./img/zoumadeng/3.jpg") },
                { url: require("./img/zoumadeng/4.jpg") },
                { url: require("./img/zoumadeng/5.jpg") }
            ]
        }
    },
    computed: {
        calculatedHeight() {
            if (this.windowWidth <= 1600) {
                return '80px';
            } else if (this.windowWidth > 1600 && this.windowWidth <= 1900) {
                return '90px';
            } else if (this.windowWidth > 1900 && this.windowWidth <= 2100) {
                return '100px';
            } else if (this.windowWidth > 2100) {
                return '117px';
            } else {
                return '80px';
            }
        }
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    }
}

</script>

<style>
.el-carousel-out {
    width: 100%;
}

.el-carousel {
    margin: 0px 0px;
    width: 100%;
    padding: 0px 0px;
    box-sizing: border-box;
}

.heard-image {
    margin: 0px 0px auto 0px;
    box-sizing: border-box;
    display: block;
    width: 100%;
}
</style>