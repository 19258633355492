import AES from 'crypto-js/aes'
import Base64 from 'crypto-js/enc-base64'
import Hex from 'crypto-js/enc-hex'
import Utf8 from 'crypto-js/enc-utf8'
import CBC from 'crypto-js/mode-cfb'
import NoPadding from 'crypto-js/pad-nopadding'
import LZString from 'lz-string'


const enCodeKey = "lzf453"
const secureKey= "txs453"
const aesOption = {
    iv: Utf8.parse(secureKey),
    mode: CBC,
    padding: NoPadding
}
const UTIL = {
    localStorageSupport() {
        return 'localStorage' in window && window.localStorage !== null
    },
    aesDecode(data, key) {
        if (!key) key = enCodeKey
        if (!data) return null
        let base64DeSrc = Base64.stringify(Hex.parse(data))
        let deStr = AES.decrypt(base64DeSrc, Utf8.parse(key), aesOption)
        return deStr.toString(Utf8)
    },
    localStorageGet(key) {
        if (!this.localStorageSupport()) return null
        if (!key) return null
        let value = localStorage.getItem(key)
        if (value) return this.aesDecode(LZString.decompress(value), enCodeKey)
        return null
    },
    aesEncode(data, key) {
        if (!key) key = enCodeKey

        let src

        if (typeof data === 'string') {
            src = data
        } else if (typeof data === 'object') {
            src = Utf8.parse(data)
        }

        let enStr = AES.encrypt(src, Utf8.parse(key), aesOption)

        return enStr.ciphertext.toString()
    },
    localStorageSet(key, value) {
        if (!this.localStorageSupport()) return

        if (!key || !value) return null

        if (value) {
            let enValue = this.aesEncode(value, enCodeKey)

            value = LZString.compress(enValue)
            localStorage.setItem(key, value)
        }
    },
    setUserToken(token) {
        return this.localStorageSet('fitting', token)
    },

    getUserToken() {
        return this.localStorageGet('fitting')
    },
    setListToken(token) {
        return this.localStorageSet('list', token)
    },
    getListToken() {
        return this.localStorageGet('list')
    },
    getPageToken() {
        return this.localStorageGet('page')
    },
    setPageToken(token) {
        return this.localStorageSet('page', token)
    },
}
export { UTIL }
