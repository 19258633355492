<template>
    <div class="unionTable">
        <el-table v-loading="loading" element-loading-text="加载中..." :data="tableData" class="table"
            :height="tableHeight" :style="{ width: tableWidth + 'px' }">
            <el-table-column label="院校名称" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.universityname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="院校代码" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.universitycode }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="办学类型" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.campustype }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="院校类型" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.universitytype }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专业名称" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.majorname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专业代码" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.majorcode }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专业类别" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.majorcategory }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="学位授予门类" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.degreecategory }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="选考科目要求" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.requirement }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="修业年限" :width="comWith" align: center>
                <template #default="scope">
                    <div style="display: flex; align-items: center;margin-left: -10px;">
                        <span style="margin-left: 10px">{{ scope.row.studyyears }}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-left: 10px;" v-model:current-page="PageParam.currentPage"
            v-model:page-size="PageParam.pageSize" :page-sizes="[20, 50, 100, 200, 500]" :small="small"
            :disabled="disabled" :background="background" layout="total, sizes, prev, pager, next, jumper"
            :total="PageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector';
import { UTIL } from '../util/index';
export default {
    emits: ['queryPaged'],
    components: {

    },
    data() {
        return {
            tableData: [],
            PageParam: {
                currentPage: 1,//当前页面
                pageSize: 20,//该页面能够容纳多少条
                total: 0
            },
            comWith: 140,
            loading: false,
            tableHeight: 488,
            tableWidth: 1200,
            small: true,
            disabled: false,
            background: false,
            isBack: false,
            // tableHeight:'550px'
        }
    },
    created() {
        // if (JSON.parse(UTIL.getListToken())) {
        //     this.tableData = JSON.parse(UTIL.getListToken());
        // }
        // if (JSON.parse(UTIL.getPageToken())) {
        //     this.PageParam = JSON.parse(UTIL.getPageToken());
        // }
        if (JSON.parse(localStorage.getItem('listData'))) {
            this.tableData = JSON.parse(localStorage.getItem('listData'));
        }
        if (JSON.parse(localStorage.getItem('pageData'))) {
            this.PageParam = JSON.parse(localStorage.getItem('pageData'));
        }
    },
    beforeUnmount() {
        if (this.isBack == false) {
            // UTIL.setListToken(JSON.stringify(this.tableData));
            // UTIL.setPageToken(JSON.stringify(this.PageParam));
            localStorage.setItem('listData', JSON.stringify(this.tableData));
            localStorage.setItem('pageData', JSON.stringify(this.PageParam));
        }

    },
    computed: {},
    methods: {
        handleSizeChange(newPageSize) {
            this.PageParam.pageSize = newPageSize;
            this.$emit('queryPaged');

        },
        handleCurrentChange(newCurrentPage) {
            this.$emit('queryPaged', newCurrentPage);
        },
        queryUpdate() {
            this.$emit('queryPaged', 1);
        }
    },
    mounted() {
        const erd = elementResizeDetectorMaker();
        erd.listenTo(document.getElementsByClassName("home"), (e) => {
            if (Number(e.offsetHeight) > 1000) {
                this.tableHeight = e.offsetHeight - 240;
            } else {
                this.tableHeight = e.offsetHeight - 210;
            }
            if (Number(e.offsetWidth) < 500) {
                this.tableHeight = e.offsetHeight - 400;
            }
            if (Number(e.offsetWidth) > 1350) {
                this.tableWidth = e.offsetWidth;
            } else {
                this.tableWidth = e.offsetWidth - 30;
            }
        });
    }
}

</script>

<style>
.el-pagination {
    display: flex !important;
    /* justify-content: center; */
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
}

.table {
    table-layout: auto !important;
    text-align: center;
    padding-left: 10px;
    box-sizing: border-box;
}

.unionTable {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    box-sizing: border-box;
    text-align: center;
}

.el-table__inner-wrapper {
    width: calc(100% - 20px);
}

.unionTable span {
    /* filter: brightness(20%); */
    color: #000000;
}

.el-table__header {
    width: 100% !important;
}

.el-table__body {
    width: 100% !important;
}
</style>