<template>
    <div class="regester">
        <img src="./img/LOGO.png" alt="Logo" class="logo">
        <div class="regester-form">
            <div class="regester-form-title">
                <span>用 户 注 册</span>
            </div>
            <div class="regester-form-fo">
                <el-form ref="userFormRef" :model="classData" :rules="myRules" label-width="100px">
                    <el-form-item label="用户名:" prop="name">
                        <el-input v-model="classData.name" placeholder="请输入用户名" clearable autocomplete="off" required
                            style="width: 300px;" />
                    </el-form-item>
                    <el-form-item label="密码:" prop="password">
                        <el-input v-model="classData.password" clearable show-password placeholder="请输入密码"
                            autocomplete="off" required style="width: 300px;" />
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="ispassword">
                        <el-input v-model="classData.ispassword" @blur="pdblur" clearable show-password
                            placeholder="请再次输入密码" autocomplete="off" required style="width: 300px;" />
                    </el-form-item>
                    <el-form-item label="身份:" prop="identity">
                        <el-select v-model="classData.identity" clearable @change="identityChange" placeholder="请选择身份类型"
                            style="width: 300px;">
                            <el-option v-for="item in identities" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="省份:" prop="province">
                        <el-select v-model="classData.province" clearable placeholder="请选择所在省份" style="width: 300px;">
                            <el-option v-for="item in provinces" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所选大学" :prop="prop1" v-if="isShow1">
                        <el-autocomplete v-model="classData.selectedsub" :fetch-suggestions="querySearch" clearable
                            highlight-first-item="true" :trigger-on-focus="true" class="inline-input w-50"
                            placeholder="请输入所选大学" @select="handleSelect" @change="handChange" style="width: 300px;" />
                    </el-form-item>
                    <el-form-item label="所选科目" :prop="prop2" v-if="isShow2">
                        <el-select v-model="oneSelect" clearable placeholder="请选择首先科目" style="width:150px ;">
                            <el-option v-for="item in one" :label="item" :value="item" />
                        </el-select>
                        <el-select v-model="towSelect" multiple :disabled="isDisabled" placeholder="请选择再选科目"
                            @change="twoChange" clearable multiple-limit="2" style="width:150px ;">
                            <el-option v-for="item in two" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="验证:">
                        <div style="width: 340px;display: flex;justify-content: left;align-items: center;">
                            <el-input v-model="userInput" clearable autocomplete="off" required placeholder="请输入验证码"
                                style="width:190px ;" />
                            <img :style="{ width: '100px', height: '30px', borderRadius: '5px' }"
                                :src="randomNumberImageUrl" alt="随机数字图片">
                            <el-button @click="checkInput" type="success"
                                style="margin-left: 10px;width: 60px;">验证</el-button>
                            <el-button @click="updateRandomNumber" style="margin-left: 10px;" circle>
                                <el-icon>
                                    <Refresh />
                                </el-icon></el-button>
                        </div>
                    </el-form-item>
                    <div style="display: flex;justify-content:center;align-items: center;padding-left: 80px;">
                        <el-button @click="cancel">取消</el-button>
                        <el-button :disabled="disabledyz" type="primary" @click="submitData" style="margin-left: 50px;">
                            提交
                        </el-button>
                    </div>
                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
import { Refresh } from '@element-plus/icons-vue';
import userRequest from '@/network/userRequest';
import { ElMessage, ElMessageBox } from 'element-plus';
import store from '../store';
import { UTIL } from '../util/index';
export default {
    //组件注入
    components: {
    },
    //变量初始化
    data() {
        return {
            labelPosition: 'top',
            randomNumber: 0,
            userInput: '',
            disabledyz: true,
            isShow1: false,
            isShow2: false,
            isDisabled: false,
            prop1: 'selectedsub',
            prop2: '',
            select: [],
            oneSelect: '',
            towSelect: [],
            classData: {
                id: '',
                name: '',
                password: '',
                identity: '',
                province: '',
                selectedsub: '',
                ispassword: '',
            },
            provinces: [],
            identities: ['高中生', '初中生'],
            one: ['物理', '历史'],
            two: ['思想政治', '地理', '化学', '生物'],
            datas: [],
            myRules: {
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 2, max: 30, message: '请输入2~30个字符', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            // 使用正则表达式检查是否包含空格、特殊符号或中文字符
                            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
                            if (!regex.test(value)) {
                                callback(new Error('用户名只能包含字母、数字、下划线和中文字符'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            // 使用正则表达式检查是否包含空格或特殊符号
                            const regex = /^[a-zA-Z0-9_]+$/; // 此正则表达式允许字母、数字和下划线
                            if (!regex.test(value)) {
                                callback(new Error('密码只能包含字母、数字和下划线'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                ispassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            // 使用正则表达式检查是否包含空格或特殊符号
                            const regex = /^[a-zA-Z0-9_]+$/; // 此正则表达式允许字母、数字和下划线
                            if (!regex.test(value)) {
                                callback(new Error('密码只能包含字母、数字和下划线'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },

                ],
                identity: [
                    { required: true, message: '请选择身份', trigger: 'blur' },
                    // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
                ],
                province: [
                    { required: true, message: '请输入学省份', trigger: 'blur' },
                    // { min: 1, max: 50, message: '请输入2~50个字符', trigger: 'blur' },
                ],
                selectedsub: [
                    { required: true, message: '请输入所选大学', trigger: 'blur' },
                    { min: 3, max: 50, message: '请输入4~50个字符', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            // 使用正则表达式检查是否包含空格、特殊符号或中文字符
                            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
                            if (!regex.test(value)) {
                                callback(new Error('只能包含中文字符和字母、数字、下划线'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],

            },
            restaurants: [],

        }
    },
    computed: {
        randomNumberImageUrl() {
            // 使用计算属性动态生成图片URL，包含随机数和时间戳，确保每次都是新的URL
            return `https://dummyimage.com/150x50/000/fff&text=${this.randomNumber}&${Date.now()}`;
        },
    },
    //方法定义
    methods: {
        drawCaptcha() {
            const canvas = this.$refs.captchaCanvas
            const ctx = canvas.getContext('2d')
            const captchaText = '' + this.randomNumber
            ctx.font = "48px serif"
            ctx.fillText(captchaText, 60, 75)
            // TODO: 添加多种颜色以及弯曲和扭曲效果，增强验证码安全性
        },
        // 在需要更新随机数的地方调用此方法
        updateRandomNumber() {
            // 这里可以使用你的逻辑来更新随机数，例如发起请求获取新的随机数
            // 在这个例子中，简单地使用 Math.random() 生成一个新的随机数
            userRequest.newRandom().then(response => {
                if (response.status == 200) {
                    this.randomNumber = response.data;
                } else {
                    ElMessage.error(response.data.message);
                }
            }).catch(error => {
                ElMessage.error(error);
            })
        },
        checkInput() {
            if (parseInt(this.userInput) === this.randomNumber) {
                ElMessageBox({
                    title: '提示',
                    message: '验证成功！',
                    type: 'success'
                })
                // alert('验证成功！');
                // 验证成功后的逻辑
                this.disabledyz = false;
            } else {
                ElMessageBox({
                    title: '提示',
                    message: '验证失败，请重新输入！',
                    type: 'warning'
                })
                // alert('验证失败，请重新输入！');
                // 验证失败后的逻辑
                this.userInput = '';
            }
        },
        // generateRandomNumber() {
        //     return Math.floor(1000 + Math.random() * 9000);
        // },

        pdblur() {
            if (this.classData.ispassword != this.classData.password) {
                ElMessage.error('密码不匹配，请再次输入');
                this.classData.ispassword = '';
            }
        },
        twoChange(value) {
        },
        handleSelect(item) {
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                )
            }
        },
        //输入框获取焦点时调用的方法
        querySearch(queryString, cb) {
            //allInfos是怎么来，是从父组件传过来的还是在自己组件调用api接口拿到的还是其他
            //我这里的allInfos是从父组件传过来的，在这里也举例子组件从父组件传值
            let results = this.restaurants;
            results = queryString
                ? results.filter(this.createFilter(queryString))
                : results;
            //cb是回调函数，返回筛选出的结果数据到输入框下面的输入列表
            cb(results);
        },

        resetAllData() {
            for (let pro in this.classData) {
                this.classData[pro] = '';
            }
        },
        identityChange() {
            if (this.classData.identity == "初中生") {
                this.isShow1 = true;
                this.isShow2 = false;
                this.prop1 = "selectedsub";
            }
            else if (this.classData.identity == "高中生") {
                this.isShow2 = true;
                this.isShow1 = false;
                this.prop1 = "";
            }
        },
        cancel() {
            this.$router.replace("/login");
        },
        submitData() {
            if (this.classData.identity == "高中生") {
                this.prop1 = '';
                if (this.oneSelect == '') {
                    ElMessage.info("请选择首选科目");
                }
                else if (this.towSelect.length != 2) {
                    ElMessage.info("请选择两门再选科目");
                }
                else {
                    this.classData.selectedsub = "" + this.oneSelect + "," + this.towSelect[0] + "," + this.towSelect[1];
                    this.submit();
                }

            } else if (this.classData.identity == "初中生") {
                this.submit();
            } else {
                ElMessage.info("请选择身份");
            }
        },
        submit() {
            this.$refs.userFormRef.validate((valid, fields) => {
                if (valid) {
                    userRequest.addData(this.classData, this.userInput).then(response => {
                        this.updateRandomNumber();
                        if (response.data.resultCode == 200) {
                            store.state.userInfo.id = this.classData.id;
                            store.state.userInfo.name = this.classData.name;
                            store.state.userInfo.password = this.classData.password;
                            store.state.userInfo.identity = this.classData.identity;
                            store.state.userInfo.province = this.classData.province;
                            store.state.userInfo.selectedsub = this.classData.selectedsub;
                            store.state.isLoggedIn = false;
                            let data = JSON.stringify({
                                id: this.classData.id,
                                name: this.classData.name,
                                identity: this.classData.identity,
                                province: this.classData.province,
                                selectedsub: this.classData.selectedsub,
                                isLoggedIn: false,
                            });
                            // 构造函数
                            UTIL.setUserToken(data);
                            ElMessage({
                                message: '注册成功！',
                                type: 'success',
                            })
                            store.state.isRegester = true;
                            //这里使用了JSON.stringify将对象转换为字符串，并在接收端使用JSON.parse将其还原为对象
                            this.$router.push({ name: "login", query: { data: JSON.stringify(this.classData) } });
                            this.resetAllData();

                        } else {
                            ElMessage.error(response.data.message);
                        }
                    }).catch(error => {
                        ElMessage.info("该用户名已存在！");
                        console.log(error);
                    })
                } else {
                    console.log('erro submit!', fields);
                }
            })
        }
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
        // 组件挂载时，初始化随机数
        this.updateRandomNumber();
        userRequest.listProvince().then(response => {
            if (response.status == 200) {
                this.provinces = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })
        userRequest.listUniversity().then(response => {
            if (response.status == 200) {
                this.restaurants = response.data;
            } else {
                ElMessage.error(response.data.message);
            }
        }).catch(error => {
            ElMessage.error(error);
        })

    },
    // 当data中的数据发生变化后执行
    updated() {
    },
}

</script>

<style lang="scss" src="../assets/regester.scss" scoped></style>