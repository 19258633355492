<template>
    <div class="parent-out">
        <img src="./img/LOGO.png" alt="Logo" class="logo">
        <div class="login">
            <el-row justify="center" >
                <el-col :span="24" style="color: #000000;">
                    <h1 style="font-family: 楷体;">
                        欢 迎 进 入 高 考 报 考 通 系 统
                    </h1>
                    <h3 style="font-family: 'Pacifico', cursive;">
                        Welcome to the "National College Entrance Examination Application Pass" system
                    </h3>
                </el-col>
                <el-form ref="loginFormRef" :model="classData" label-position="left" :rules="myRules"
                    style="font-weight: bold;margin-top: 50px;">
                    <el-form-item label="用户名:" prop="name">
                        <el-input v-model="classData.name" :formatter="(value) => {
                            if (value.includes('@') || value.includes('#') || value.includes('$') ||
                                value.includes('%') || value.includes('^') || value.includes('&') ||
                                value.includes('*') || value.includes('`') || value.includes('……')) {
                                return value.replace(/[@#$%^&*……]/g, '');
                            } else {
                                return value;
                            }
                        }" autocomplete="off" required style="width:300px ;" />
                    </el-form-item>
                    <el-form-item label="密码:" prop="password">
                        <el-input v-model="classData.password" show-password autocomplete="off" required
                            :formatter="formatPassword" style="width:300px ;margin-left: 14px;" />
                    </el-form-item>
                    <el-row justify="center">
                        <el-form-item style="margin-left: 50px;">
                            <el-button type="primary" @click="login">登录</el-button>
                            <el-button type="success" @click="regester" style="margin-left: 50px;">注册</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
            </el-row>
        </div>
    </div>
</template>

<script>
import store from '../store'
export default {
    //组件注入
    components: {
    },
    //变量初始化
    data() {
        return {
            classData: {
                id: '',
                name: '',
                password: '',
                identity: '',
                province: '',
                selectedsub: '',
            },
            data: {},
            myRules: {
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 2, max: 15, message: '请输入2~15个字符', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            // 使用正则表达式检查是否包含空格、特殊符号或中文字符
                            const regex = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/; // 允许字母、数字、下划线和中文字符
                            if (!regex.test(value)) {
                                callback(new Error('用户名只能包含字母、数字、下划线和中文字符'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '请输入6~15个字符', trigger: 'blur' },
                ],
            },
        }
    },
    //方法定义
    methods: {
        formatPassword(value) {
            // 使用正则表达式检查密码是否包含字母、数字和下划线
            const regex = /^[a-zA-Z0-9_]+$/; // 只允许字母、数字和下划线
            if (!regex.test(value)) {
                return value.replace(/[^a-zA-Z0-9_]/g, ''); // 移除非字母、数字和下划线的字符
            } else {
                return value;
            }
        },
        login() {
            this.$refs.loginFormRef.validate(async (valid, fields) => {
                if (valid) {
                    const result = await this.$store.dispatch('login', this.classData);
                    if (result.success) {
                        this.$router.push('/home');
                        this.$message.success(result.message);
                    } else {
                        this.$message.error(result.message);
                    }
                }
            })
        },
        regester() {
            this.$router.replace("/regester");
        }
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
        // this.disablePageZoom();
        if (store.state.isRegester == true) {
            //使用JSON.parse将其还原为对象
            this.data = JSON.parse(this.$route.query.data);
            this.classData.id = this.data.id;
            this.classData.name = this.data.name;
            this.classData.identity = this.data.identity;
            this.classData.password = this.data.password;
            this.classData.province = this.data.province;
            this.classData.selectedsub = this.data.selectedsub;
        }
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
}

</script>

<style lang="scss" src="../assets/login.scss" scoped>
</style>