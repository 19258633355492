import axios  from "axios";
import Cookies from 'js-cookie';
import store from '../store';
import { UTIL } from '../util/index';
export function request(config){
  const instance = axios.create({
      baseURL:'https://cloud.gzgkzytb.cn',
      // baseURL:'http://localhost:8443',
      timeout: 10000, 
  });
  instance.interceptors.request.use(function (config) {
    if(JSON.parse(UTIL.getUserToken())){
      config.headers['token'] = JSON.parse(UTIL.getUserToken()).token;
    }
    // 设置Cookie的SameSite属性为None，并启用Secure
    Cookies.set('token', store.state.userInfo.token, { sameSite: 'None', secure: true });
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
  
  return instance;
}





