import { createRouter, createWebHistory } from 'vue-router'
import login from '../components/login.vue'
import regester from '../components/regester.vue'
import home from '../components/home.vue'
import universityMajorCom from '../components/universityMajorCom/universityMajorCom.vue'
import majorCom from '../components/majorCom/majorCom.vue'
import { UTIL } from '../util/index';

const router = createRouter({
  mode: 'hash',
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {  
      path: '/', 
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/regester',
      name: 'regester',
      component: regester,
    },
    {
      path: '/home',
      name: 'home',
      component: home,
      meta: {
        keepAlive: true, 
        isBack: false
          }

    },
    {
      path: '/universityMajor',
      name: 'universityMajor',
      component: universityMajorCom,
    },
    {
      path: '/major',
      name: 'major',
      component: majorCom,
    },


  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {
  //登录及注册页面可以直接进入,而主页面需要分情况
  if (to.path == '/login') {
    next();
  }
  else if (to.path == '/regester') {
    next();
  }
  else {
    if (from.path == "/login")//从登录页面可以直接通过登录进入主页面
    {
      next();
    }
    else {
      if (JSON.parse(UTIL.getUserToken())) {
        if (JSON.parse(UTIL.getUserToken()).isLoggedIn === true) {
          next();
        }
      }
      else {
        next('/login');
        console.log("需要登录");
      }
    }
  }
})
export default router
