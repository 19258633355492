<template>
    <div class="parent-out-home">
        <div class="home">
            <el-page-header @back="onBack" title="退出">
                <template #content>
                    <span class="text-large font-600 mr-3"> 高考报考通管理系统 </span>
                </template>
            </el-page-header>
            <headerCom></headerCom>
            <div class="userDataRow">
                <div>姓名：<span class="userData">{{ this.$store.state.userInfo.name
                }}</span></div>
                <div>身份：<span class="userData">{{ this.$store.state.userInfo.identity }}</span></div>
                <div>省份：<span class="userData">{{ this.$store.state.userInfo.province }}</span></div>
                <div>{{ selectedsub }}<span class="userData">{{ this.$store.state.userInfo.selectedsub
                }}</span></div>
                <div>
                    <el-button type="warning" class="col-button" @click="updataSelfData">
                        <el-icon :size="18" style="margin-bottom: 3px;margin-right: 2px;">
                            <Edit />
                        </el-icon>更改信息
                    </el-button>
                </div>
                <div>
                    <el-button type="success" :loading="ploading" class="col-button" @click="queryPaged">
                        <el-icon :size="18" style="margin-bottom: 3px;margin-right: 2px;">
                            <Search />
                        </el-icon>一键匹配
                    </el-button>
                </div>
                <div>
                    <el-button type="danger" class="col-button" @click="opeanSRTable">
                        <el-icon :size="18" style="margin-bottom: 3px;margin-right: 2px;">
                            <OfficeBuilding />
                        </el-icon>高等院校专业选考科目要求表
                    </el-button>
                </div>
                <div>
                    <el-button type="danger" class="col-button" @click="opeanMajorTable">
                        <el-icon :size="18" style="margin-bottom: 3px;margin-right: 2px;">
                            <Tickets />
                        </el-icon>专业目录
                    </el-button>
                </div>
            </div>
            <QueryToolBarGao ref="QueryToolBarGaoRef" @queryPaged="queryPaged"></QueryToolBarGao>
            <unionTable ref="unionTableRef" @queryPaged="queryPaged"></unionTable>
            <updateDialog ref="updateDialogRef" @identityChange="identityChange"></updateDialog>
        </div>
    </div>
</template>

<script>
import { UTIL } from '../util/index';
import QueryToolBarGao from './QueryToolBarGao.vue';
import headerCom from './headerCom.vue';
import { Edit, Search, OfficeBuilding, Tickets } from '@element-plus/icons-vue';
import unionTable from './unionTable.vue';
import userRequest from '@/network/userRequest';
import updateDialog from './updateDialog.vue';
export default {
    //组件注入
    components: {
        headerCom,
        unionTable,
        updateDialog,
        QueryToolBarGao,
    },
    //变量初始化
    data() {
        return {
            selectedsub: '所选学科：',
            ploading: false,
            param: {
                username: '',
                identity: '',
                province: '',
                conditions: '',
                querydate: '',
            },
            queryTop: '-15px'

        }
    },
    //方法定义
    methods: {
        opeanMajorTable() {
            this.$router.replace({ name: 'major' });
        },
        opeanSRTable() {
            this.$router.replace({ name: 'universityMajor' });
        },
        addQueryRecord() {
            this.param.username = this.$store.state.userInfo.name;
            this.param.identity = this.$store.state.userInfo.identity;
            this.param.province = this.$store.state.userInfo.province;
            this.param.conditions = this.$store.state.userInfo.selectedsub;
            userRequest.addQueryRecord(this.param).then(response => {
            }).catch(error => {
                console.log(error);
            })
        },
        identityChange() {
            if (this.$store.state.userInfo.identity == '高中生') {
                this.$refs.QueryToolBarGaoRef.isShow = true;
                this.$refs.unionTableRef.tableHeight = '480px';
                this.queryTop = '-25px';
            }
            else if (this.$store.state.userInfo.identity == '初中生') {
                this.$refs.QueryToolBarGaoRef.isShow = false;
                this.$refs.unionTableRef.tableHeight = '525px';
                this.queryTop = '0px';
            }
        },
        updataSelfData() {
            this.$refs.updateDialogRef.classData.id = this.$store.state.userInfo.id;
            this.$refs.updateDialogRef.classData.name = this.$store.state.userInfo.name;
            this.$refs.updateDialogRef.classData.password = this.$store.state.userInfo.password;
            this.$refs.updateDialogRef.classData.ispassword = this.$store.state.userInfo.password;
            this.$refs.updateDialogRef.classData.identity = this.$store.state.userInfo.identity;
            this.$refs.updateDialogRef.classData.province = this.$store.state.userInfo.province;
            this.$refs.updateDialogRef.classData.selectedsub = this.$store.state.userInfo.selectedsub;
            this.$refs.updateDialogRef.isVisible = true;
            this.$refs.updateDialogRef.identityChange();
        },
        onBack() {
            this.$refs.unionTableRef.isBack = true;
            this.$store.commit('setLoggedIn', false);
            localStorage.clear();
            window.sessionStorage.clear();
            this.$router.push("/login");
            window.location.reload();
        },
        queryPaged(newCurrentPage) {
            this.$refs.unionTableRef.loading = true;
            this.ploading = true;
            let param = {};
            let pagedParam = this.$refs.unionTableRef.PageParam;
            if (newCurrentPage && newCurrentPage > 1) {
                param.page = pagedParam.currentPage;
            } else {
                param.page = 1;
                this.$refs.unionTableRef.PageParam.currentPage = 1;
            }
            param.rows = pagedParam.pageSize;
            this.addQueryRecord();
            if (this.$store.state.userInfo.identity == '高中生') {
                let li = this.$store.state.userInfo.selectedsub.split(",");
                param.one = li[0];
                param.two = li[1];
                param.three = li[2];
                param.universityName = this.$refs.QueryToolBarGaoRef.classParam.universityName;
                param.campustype = this.$refs.QueryToolBarGaoRef.classParam.campustype;
                param.universitytype = this.$refs.QueryToolBarGaoRef.classParam.universitytype;
                param.majorcategory = this.$refs.QueryToolBarGaoRef.classParam.majorcategory;
                userRequest.queryParamBySub(param).then(response => {
                    if (response.data && response.data.rows) {
                        let total = response.data.total;
                        let rows = response.data.rows;
                        this.$refs.unionTableRef.tableData = rows;
                        this.$refs.unionTableRef.PageParam.total = total;
                        this.$refs.unionTableRef.loading = false;
                        this.ploading = false;
                    }
                }).catch(error => {
                    console.log(error);
                })
            } else {
                param.selectUn = this.$store.state.userInfo.selectedsub;
                userRequest.queryParamBySub(param).then(response => {
                    if (response.data && response.data.rows) {
                        let total = response.data.total;
                        let rows = response.data.rows;
                        this.$refs.unionTableRef.tableData = rows;
                        this.$refs.unionTableRef.PageParam.total = total;
                        this.$refs.unionTableRef.loading = false;
                        this.ploading = false;
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
        },
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
        if (JSON.parse(UTIL.getUserToken())) {
            if (JSON.parse(UTIL.getUserToken()).isLoggedIn === true) {
                this.$store.state.userInfo = JSON.parse(UTIL.getUserToken());
            }
        }

        if (this.$store.state.userInfo.identity == "初中生") {
            this.selectedsub = '所选大学：';
        }
        else if (this.$store.state.userInfo.identity == "高中生") {
            this.selectedsub = '所选学科：';
        }
        this.identityChange();
    },
    // 当data中的数据发生变化后执行
    updated() {

    },
}

</script>

<style>
.home-unionTable {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    box-sizing: border-box;
}

.parent-out-home {
    height: 100%;
    width: 100%;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.home {
    font-family: Verdana, sans-serif;
    height: 100%;
    width: calc(100% - 20px);
    /* overflow: hidden; */
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    /* margin: 1px 2px 0px 2px; */
}

.home-row {
    background-color: rgb(183, 221, 210);
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-button {
    margin-top: 4px;
}

.userData {
    color: red;
    border-bottom: 2px dashed #000000;
}

.userDataRow {
    /* text-align: left; */
    display: flex !important;
    ;
    /* justify-content: center; */
    align-items: center;
    justify-content: left;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.userDataRow div {
    margin-left: 20px;
    box-sizing: border-box;
    display: inline;
}
</style>