<template>
    <div class="parent-out-major">
        <div class="majorClass">
            <el-page-header @back="onBack" title="返回">
                <template #content>
                    <span class="text-large font-600 mr-3"> 普通高等学校本科专业目录 </span>
                </template>
            </el-page-header>
            <el-row>
                <QueryToolBar ref="QueryToolBarRef" @queryPaged="queryPaged"></QueryToolBar>
            </el-row>
            <el-row>
                <majorTable ref="majorTableRef" @queryPaged="queryPaged"></majorTable>
            </el-row>
        </div>
    </div>
</template>

<script>
import userRequest from '@/network/userRequest';
import QueryToolBar from './QueryToolBar.vue'
import majorTable from './majorTable.vue'
export default {
    //组件注入
    components: {
        QueryToolBar,
        majorTable,
    },
    //变量初始化
    data() {
        return {

        }
    },
    //方法定义
    methods: {
        onBack() {
            this.$router.push("/home");
        },
        queryPaged(newCurrentPage) {
            this.$refs.majorTableRef.loading = true;
            let param = this.$refs.QueryToolBarRef.classParam;
            let pagedParam = this.$refs.majorTableRef.PageParam;
            if (newCurrentPage && newCurrentPage > 1) {
                param.page = pagedParam.currentPage;
            } else {
                param.page = 1;
                this.$refs.majorTableRef.PageParam.currentPage = 1;
            }
            param.rows = pagedParam.pageSize;
            userRequest.queryPageMajor(param).then(response => {
                if (response.data && response.data.rows) {
                    let total = response.data.total;
                    let rows = response.data.rows;
                    this.$refs.majorTableRef.tableData = rows;
                    this.$refs.majorTableRef.PageParam.total = total;
                    this.$refs.majorTableRef.loading = false;
                }
            }).catch(error => {
                console.log(error);
            })
        },
    },
    // 在组件内容被渲染到页面之后自动执行的函数
    mounted() {
    },
    // 当data中的数据发生变化后执行
    updated() {
    },
};

</script>

<style>
.parent-out-major{
    width: 100%;
    height: 100%;
    box-sizing: border-box;

}
.majorClass {
    font-family: Verdana, sans-serif;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-weight: bold;
}
</style>