<template>
    <div class="srtable">
        <el-table v-loading="loading" element-loading-text="加载中..." :data="tableData" class="table"
            :height="tableHeight" :style="{ width: tableWidth + 'px' }">
            <el-table-column label="院校名称" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专业名称" :width="comWith">
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <span>{{ scope.row.majorname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专业选考科目要求" :width="comWith" align: center>
                <template #default="scope">
                    <div style="display: flex; align-items: center;margin-left: -10px;">
                        <span style="margin-left: 10px">{{ scope.row.requirement }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center">
                <template #default="scope">

                </template>
            </el-table-column>
        </el-table>

        <el-pagination v-model:current-page="PageParam.currentPage" v-model:page-size="PageParam.pageSize"
            :page-sizes="[20, 50, 100, 200, 500, 1000]" :small="small" :disabled="disabled" :background="background"
            layout="total, sizes, prev, pager, next, jumper" :total="PageParam.total" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" style="margin-left: 10px;" />
    </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector';
export default {
    emits: ['queryPaged'],
    components: {
    },
    data() {
        return {
            tableData: [],
            PageParam: {
                currentPage: 1,//当前页面
                pageSize: 20,//该页面能够容纳多少条
                total: 0
            },
            title: "修改班级",
            comWith: 200,
            loading: false,
            small: true,
            disabled: false,
            background: false,
            tableWidth: 1500,
            tableHeight: 720,
        }
    },
    methods: {
        handleSizeChange(newPageSize) {
            this.PageParam.pageSize = newPageSize;
            this.$emit('queryPaged');

        },
        handleCurrentChange(newCurrentPage) {
            this.$emit('queryPaged', newCurrentPage);
        },
        queryUpdate() {
            this.$emit('queryPaged', 1);
        }
    },
    mounted() {
        this.queryUpdate();
        const erd = elementResizeDetectorMaker();
        erd.listenTo(document.getElementsByClassName("srClass"), (e) => {
            if (Number(e.offsetHeight) > 1000) {
                this.tableHeight = e.offsetHeight - 120;
            } else {
                this.tableHeight = e.offsetHeight - 120;
            }
            if (Number(e.offsetWidth) < 500) {
                this.tableHeight = e.offsetHeight;
            }
            if (Number(e.offsetWidth) > 1350) {
                this.tableWidth = e.offsetWidth;
            } else {
                this.tableWidth = e.offsetWidth - 50;
            }
        });
    }
}

</script>

<style>
.el-pagination {
    display: flex !important;
    /* justify-content: center; */
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
}
.srtable {
    width: 100%;
    height: 100%;
}

.el-table__header {
    width: 100% !important;
}

.el-table__body {
    width: 100% !important;
}
</style>