// store/index.js
import axios from 'axios';
import { createStore } from 'vuex';
import userRequest from '@/network/userRequest'; // 导入处理用户请求的 API 模块
import { UTIL } from '../util/index';

export default createStore({
  state: {
    isLoggedIn: false,
    userInfo: {
      id: '',
      name: '',
      password: '',
      identity: '',
      province: '',
      selectedsub: '',
      token: '',
    },
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await userRequest.login(credentials);

        if (response.data.code === 1) {
          const userInfo = response.data.data;
          // console.log(userInfo);
          userInfo.password = credentials.password;
          commit('setUserInfo', userInfo);
          commit('setLoggedIn', true);
          const data = JSON.stringify({
            id: userInfo.id,
            name: userInfo.name,
            password: credentials.password,
            identity: userInfo.identity,
            province: userInfo.province,
            selectedsub: userInfo.selectedsub,
            token: userInfo.token,
            isLoggedIn: true,
          });
          UTIL.setUserToken(data);
          /**
           * 设置定时器
           * 5小时后失效
           */
          const timeoutInMilliseconds = 5 * 60 * 60 * 1000; //单位毫秒

          setTimeout(() => {
            // 在定时器回调函数中删除数据
            localStorage.removeItem('fitting');
          }, timeoutInMilliseconds);
          return { success: true, message: '登录成功' };
        } else {
          return { success: false, message: '账号或密码错误' };
        }
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    // ...其他 getters
  },
});
